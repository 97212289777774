<template>
  <Layout class="OrderManagement">
    <div class="nav-bar">
      <el-tabs
        v-model="type"
        type="card"
      >
        <el-tab-pane
          label="全部"
          name="all"
        />
      </el-tabs>
    </div>
    <div
      v-loading="dataListInfo.loading"
      class="table"
    >
      <div class="header">
        <div class="goods-info col-1">
          商品信息
        </div>
        <div class="price col-2">
          原价(美元)
        </div>
        <div class="userName col-3">
          用户名/UID
        </div>
        <div class="ReceivingGoods col-4">
          收货信息
        </div>
        <div class="freight col-5">
          运费
        </div>
        <div class="AmountActuallyReceived col-6">
          实收金额
        </div>
        <div class="orderStatus col-7">
          订单状态
        </div>
      </div>
      <div class="body">
        <template v-if="dataListInfo.list.length">
          <TableRow
            v-for="(item, index) in dataListInfo.list"
            :key="index"
            :row="item"
          />
        </template>
        <template v-else>
          <div class="empty">
            暂无数据
          </div>
        </template>
      </div>
    </div>
    <Pagination
      :total="dataListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryOrderList"
    />
  </Layout>
</template>

<script>
import { getMallOrderList } from '@/api/mall-server'
import TableRow from '@/views/mall-server/components/TableRow'
export default {
  name: 'OrderManagement',
  components: { TableRow },
  data () {
    return {
      type: 'all',
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      dataListInfo: {
        loading: false,
        total: 0,
        list: []
      }
    }
  },
  created () {
    this.queryOrderList()
  },
  methods: {
    queryOrderList () {
      this.dataListInfo.loading = true
      getMallOrderList(this.formData)
        .then(res => {
          this.dataListInfo.list = res.data.list || []
          this.dataListInfo.total = res.data.total
        })
        .finally(() => {
          this.dataListInfo.loading = false
        })
    }
  }
}
</script>

<style lang="less">
  .OrderManagement{
    .layout-content{
      .col-1{flex: 200; min-width: 200px;}
      .col-2{flex: 80; min-width: unset; }
      .col-3{flex: 200; min-width: 200px; }
      .col-4{flex: 200; min-width: unset; }
      .col-5{flex: 80; min-width: unset;}
      .col-6{flex: 80; min-width: unset;}
      .col-7{flex: 120; min-width: unset; }
    }
  }
</style>
<style scoped lang="less">
  .table{
    overflow-x: auto;
    .header{
      display: flex;
      border-bottom: 1px solid #EBEEF5;
      padding: 0 20px;
      >div{
        color: #909399;
        font-weight: bolder;
        font-size: 14px;
        line-height: 40px;
        &.orderStatus{
          text-align: center;
        }
      }
    }
    .body{
      .empty{
        text-align: center;
        font-size: 14px;
        color: #909399;
        line-height: 60px;
        border-bottom: 1px solid #EBEEF5;
      }
    }
  }
</style>

<template>
  <div class="TableRow">
    <div class="row-header">
      <div class="item">
        CDKey: {{ row.cdkInfo || '/' }}
      </div>
      <div class="item">
        订单号: {{ row.orderNumStr || '/' }}
      </div>
      <div class="item">
        订单时间: {{ row.orderTime || '/' }}
      </div>
      <div class="item">
        商品ID: {{ row.goodsId || '/' }}
      </div>
    </div>
    <div class="row-body">
      <div class="block-info col-1">
        <el-image
          class="goods-cover"
          fit="contain"
          :src="row.image"
          lazy
        />
        <span>{{ row.goodsName }}</span>
      </div>
      <div class="col-2">
        {{ row.goodsPrice | priceFilter }}
      </div>
      <div class="col-3">
        <div class="username">
          UserName: {{ row.userName }}
        </div>
        <div class="uid">
          UID: {{ row.userIdStr }}
        </div>
      </div>
      <div class="col-4">
        <div>{{ userInfo.firstName + ' ' + userInfo.lastName }}</div>
        <div>{{ userInfo.address }}</div>
        <div>{{ userInfo.county + ' , ' + userInfo.province + ' ' }}{{ userInfo.postCode }}</div>
        <div>{{ userInfo.phoneNum }}</div>
      </div>
      <div class="col-5">
        {{ row.shippingPrice | priceFilter }}
      </div>
      <div class="col-6">
        {{ row.actualPayment | priceFilter }}
      </div>
      <div class="col-7 ctrl-area">
        <div>{{ (MallOrderStatusMap[row.orderStatus] || {}).name || '未知' }}</div>
        <el-select
          v-model="tempOrderStatus"
          v-loading="updateLoading"
          :loading="updateLoading"
          clearable
          placeholder="变更状态"
          style="margin-top: 10px;"
          @change="updateMallOrderStatus"
        >
          <el-option
            v-for="item in MallOrderStatusList"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { MallOrderStatusMap, MallOrderStatusList } from '@/utils/selectOptions'
import { updateMallOrderStatus } from '@/api/mall-server'

export default {
  name: 'TableRow',
  filters: {
    priceFilter (price) {
      if (!price) return '/'
      return '$' + price.toFixed(2)
    }
  },
  props: {
    row: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      MallOrderStatusMap,
      MallOrderStatusList,
      tempOrderStatus: '',
      updateLoading: false
    }
  },
  computed: {
    userInfo () {
      return this.row.userInfo || {}
    }
  },
  methods: {
    updateMallOrderStatus () {
      if (!this.tempOrderStatus) return
      this.updateLoading = true
      updateMallOrderStatus({
        id: this.row.id,
        status: this.tempOrderStatus
      })
        .then(res => {
          if (res.code === 200) {
            this.$set(this.row, 'orderStatus', this.tempOrderStatus)
          }
        })
        .finally(() => {
          this.updateLoading = false
          this.tempOrderStatus = ''
        })
    }
  }
}
</script>

<style scoped lang="less">
  .TableRow{
    border: 1px solid #EBEEF5;
    margin-top: 10px;
    .row-header{
      display: flex;
      background-color: #f2f2f4;
      font-size: 14px;
      padding: 0 20px;
      .item{
        margin-right: 20px;
        line-height: 40px;
      }
    }
    .row-body{
      display: flex;
      padding: 20px;
      font-size: 14px;
      line-height: 22px;
      .block-info{
        flex-direction: row;
        align-items: center;
        justify-content: normal;
        .goods-cover{
          width: 80px;
          height: 80px;
          flex-shrink: 0;
          margin-right: 10px;
        }
      }
      >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.ctrl-area{
          align-items: center;
        }
      }
    }
  }
</style>
